import { Box, styled } from "@mui/material";
// @ts-ignore ts(2307)
import inputLoadingGif from "../../../assets/gif/input-loading.gif";

const LoadFileIcon = () => (
  <Wrapper>
    <img src={inputLoadingGif} alt="loading..." />
  </Wrapper>
);

export default LoadFileIcon;

const Wrapper = styled(Box)(() => ({
  width: "14px",
  height: "14px",
  marginRight: "8px",
  "& img": { width: "100%", height: "100%" },
}));
