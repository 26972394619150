import { useAuthenticator } from "@aws-amplify/ui-react";

import { LeakFinderSettingsInput } from "../../API";
import { UnitsEnum } from "../../leak-finder/pages/UploadVideoGroupPage";
import { AmplifyAuthStatus } from "../models/amplify";

export const useAuthenticatedUser = () => {
  // NOTE: To prevent undesired re-renders, you can pass a function to useAuthenticator that takes
  // in Authenticator context and returns an array of desired context values.
  // The hook will only trigger re-render if any of the array values change.
  // https://ui.docs.amplify.aws/react/connected-components/authenticator/advanced#prevent-re-renders
  const { user, route, authStatus } = useAuthenticator(context => [
    context.user,
    context.route,
    context.authStatus,
  ]);

  const payload = user?.getSignInUserSession()?.getIdToken()?.payload;

  const role = payload?.role;
  const userGroups = payload?.user_groups;
  const customerId = payload?.customerId;
  let userSettings: LeakFinderSettingsInput | null = null;

  try {
    if (payload?.userSettings) {
      const settings = JSON.parse(payload.userSettings);

      userSettings = {
        defaultCameraFov: +settings?.defaultCameraFov?.N ?? 27.5,
        defaultWindspeedUnit:
          settings?.defaultWindspeedUnit?.S ?? UnitsEnum.MPH,
      };
    }
  } catch (error) {
    console.error(error);
  }

  return {
    authenticated: route === AmplifyAuthStatus.AUTHENTICATED,
    authStatus,
    user,
    route,
    customerId,
    role,
    userGroups,
    userSettings,
  };
};
