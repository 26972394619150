import { useTheme } from "@mui/material/styles";

const CloseDialogItemIcon = ({
  onClose,
}: {
  onClose?: () => void;
}): JSX.Element => {
  const theme = useTheme();

  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      cursor="pointer"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClose}
    >
      <path
        d="M13 1.5L1 13.5M13 13.5L1 1.50001"
        stroke={theme.palette.text.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default CloseDialogItemIcon;
