import { useState } from "react";

import EditDTObjectForm from "../../components/EditDTObjectForm";

import { EditDTObjectContext } from "./EditDTObjectContext";

import { DTObject } from "../../../../../API";

export const EditDTObjectContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const [objectDataToEdit, setObjectDataToEdit] = useState<DTObject | null>(
    null
  );

  const openEditDTObjectForm = (data: DTObject) => {
    if (!!data) {
      closeEditDTObjectForm();
    }

    setObjectDataToEdit(data);
  };

  const closeEditDTObjectForm = () => setObjectDataToEdit(null);

  return (
    <EditDTObjectContext.Provider
      value={{
        objectDataToEdit,
        openEditDTObjectForm,
        closeEditDTObjectForm,
      }}
    >
      {children}

      {objectDataToEdit && (
        <EditDTObjectForm
          objectDataToEdit={objectDataToEdit}
          onClose={closeEditDTObjectForm}
        />
      )}
    </EditDTObjectContext.Provider>
  );
};
