import { useContext, useMemo } from "react";
import { Box, Typography, Divider, styled, DividerProps } from "@mui/material";

import TowerNameInput from "./TowerNameInput";
import TurnOnMoveModeButton from "./TurnOnMoveModeButton";
import TurnOnRotateModeButton from "./TurnOnRotateModeButton";
import ObjectTowerGPSInput from "./ObjectTowerGPSInput";
import ObjectTowerAngleInput from "./ObjectTowerAngleInput";
import ObjectTowerDeleteButton from "./ObjectTowerDeleteButton";

import {
  ConfiguratorPermissionContext,
  ProjectContext,
} from "../../../context";
import TowerHeightInput from "./TowerHeightInput";
import { EntityTypeEnum } from "../../../context/ProjectContext/ProjectEntitesTypes";

const ObjectTowerControlPanel = () => {
  const { objects, towers, activeObjectsListId } = useContext(ProjectContext);
  const { isAllowEditing } = useContext(ConfiguratorPermissionContext);

  const allSelectedObjects = useMemo(() => {
    return [...towers, ...objects].filter(obj =>
      activeObjectsListId.includes(obj.id)
    );
  }, [activeObjectsListId, objects, towers]);

  const isOnlyTowersSelected =
    !!allSelectedObjects.length &&
    allSelectedObjects.every(item => item.EntityType === EntityTypeEnum.Tower);

  const allSelectedObjectNameAreEqual =
    !!allSelectedObjects.length &&
    allSelectedObjects.every(
      item => item && item.name === allSelectedObjects[0].name
    );

  const isActiveObject = !!activeObjectsListId.length ? "true" : "false";

  return (
    <Wrapper>
      <PanelHeader isactiveobject={isActiveObject}>
        {!!activeObjectsListId.length ? (
          <>
            <Title variant="body12Medium">
              {allSelectedObjectNameAreEqual
                ? allSelectedObjects[0].name
                : "..."}
              {` (${activeObjectsListId.length})`}
            </Title>
          </>
        ) : (
          <Title variant="body12Medium">Nothing Selected</Title>
        )}
      </PanelHeader>

      <PanelMain>
        {isAllowEditing && isOnlyTowersSelected && (
          <>
            <TowerNameInput activeTowers={allSelectedObjects} />
            <StyledDivider isactiveobject={isActiveObject} />
          </>
        )}

        {isAllowEditing && <TurnOnMoveModeButton />}
        <ObjectTowerGPSInput activeObjects={allSelectedObjects} />
        <StyledDivider isactiveobject={isActiveObject} />

        {isAllowEditing && <TurnOnRotateModeButton />}
        <ObjectTowerAngleInput activeObjects={allSelectedObjects} />

        {isOnlyTowersSelected && (
          <>
            <StyledDivider isactiveobject={isActiveObject} />
            <TowerHeightInput activeTowers={allSelectedObjects} />
          </>
        )}

        {isAllowEditing && (
          <>
            <StyledDivider isactiveobject={isActiveObject} />
            <ObjectTowerDeleteButton />
          </>
        )}
      </PanelMain>
    </Wrapper>
  );
};

export default ObjectTowerControlPanel;

const Wrapper = styled(Box)(({ theme }) => ({
  position: "absolute",
  left: 0,
  bottom: 0,
  display: "flex",
  flexDirection: "column",
  justifyContent: "start",
  backgroundColor: theme.palette["base-background"],
  border: `1px solid ${theme.palette["grey-dark"]}`,
  borderRadius: "4px",
  height: "72px",
  overflow: "hidden",
}));

const PanelHeader = styled(Box)<{ isactiveobject: "true" | "false" }>(
  ({ theme, isactiveobject }) => ({
    display: "flex",
    background:
      isactiveobject === "true"
        ? theme.palette["blue-background"]
        : theme.palette["grey-dark"],
    padding: "2px 10px",
  })
);

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.base.white,
}));

const PanelMain = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "8px 10px",
  gap: "10px",
}));

const StyledDivider = styled(
  ({ className, ...props }: DividerProps): JSX.Element => (
    <Divider {...props} orientation="vertical" flexItem />
  )
)<{
  isactiveobject: "true" | "false";
}>(({ theme, isactiveobject }) => ({
  backgroundColor:
    isactiveobject === "true"
      ? theme.palette.divider
      : theme.palette["grey-dark"],

  borderColor:
    isactiveobject === "true"
      ? theme.palette.divider
      : theme.palette["grey-dark"],
}));
