/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAllAlerts = /* GraphQL */ `
  query GetAllAlerts($customerId: String!, $limit: Int, $nextToken: String) {
    getAllAlerts(
      customerId: $customerId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        additionalEmails
        alertName
        alertType
        customService
        email
        id
        jsonObject
        location
        locationTag
        model
        node
        status
        tag
      }
      limit
      nextToken
    }
  }
`;
export const getAllEmailsForSubscriptionEntity = /* GraphQL */ `
  query GetAllEmailsForSubscriptionEntity(
    $entity: String!
    $limit: Int
    $nextToken: String
  ) {
    getAllEmailsForSubscriptionEntity(
      entity: $entity
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        email
        entity
      }
      limit
      nextToken
    }
  }
`;
export const getAllSubscriptions = /* GraphQL */ `
  query GetAllSubscriptions($entity: String!, $limit: Int, $nextToken: String) {
    getAllSubscriptions(entity: $entity, limit: $limit, nextToken: $nextToken) {
      items {
        email
        entity
      }
      limit
      nextToken
    }
  }
`;
export const getAllVideosByClient = /* GraphQL */ `
  query GetAllVideosByClient($input: GetAllVideosByClientInput) {
    getAllVideosByClient(input: $input) {
      items {
        annotations
        clientId
        customerId
        fov
        geometry
        groupId
        id
        isVideoProcessed
        modelOutputS3Key
        notes
        outputMeasurement
        sourceS3Key
        videoMethod
        videoName
        videoState
        windspeed
      }
      limit
      nextToken
    }
  }
`;
export const getAllVideosByGroup = /* GraphQL */ `
  query GetAllVideosByGroup($input: GetAllVideosByGroupInput!) {
    getAllVideosByGroup(input: $input) {
      items {
        annotations
        clientId
        customerId
        fov
        geometry
        groupId
        id
        isVideoProcessed
        modelOutputS3Key
        notes
        outputMeasurement
        sourceS3Key
        videoMethod
        videoName
        videoState
        windspeed
      }
      limit
      nextToken
    }
  }
`;
export const getAllVideosByState = /* GraphQL */ `
  query GetAllVideosByState($input: GetAllVideosByStateInput!) {
    getAllVideosByState(input: $input) {
      items {
        annotations
        clientId
        customerId
        fov
        geometry
        groupId
        id
        isVideoProcessed
        modelOutputS3Key
        notes
        outputMeasurement
        sourceS3Key
        videoMethod
        videoName
        videoState
        windspeed
      }
      limit
      nextToken
    }
  }
`;
export const getAllWildCardsForSubscriptionEntity = /* GraphQL */ `
  query GetAllWildCardsForSubscriptionEntity($limit: Int, $nextToken: String) {
    getAllWildCardsForSubscriptionEntity(limit: $limit, nextToken: $nextToken) {
      items {
        email
        entity
        wildCard
      }
      limit
      nextToken
    }
  }
`;
export const getAnnotations = /* GraphQL */ `
  query GetAnnotations($input: GetAnnotationsInput!) {
    getAnnotations(input: $input) {
      items {
        annotateTanks
        annotationType
        bottomHidden
        deviceId
        equipmentType
        id
        name
        polygon
        serviceId
        tankHeight
        tankNumber
        zoneId
      }
      limit
      nextToken
    }
  }
`;
export const getCategoriesByCustomer = /* GraphQL */ `
  query GetCategoriesByCustomer($input: GetCategoriesByCustomerInput!) {
    getCategoriesByCustomer(input: $input) {
      items {
        categoryName
        id
        isAlertEnabled
      }
      limit
      nextToken
    }
  }
`;
export const getCategoryById = /* GraphQL */ `
  query GetCategoryById($input: GetCategoryInput!) {
    getCategoryById(input: $input) {
      categoryName
      id
      isAlertEnabled
    }
  }
`;
export const getClientById = /* GraphQL */ `
  query GetClientById($input: GetClientInput!) {
    getClientById(input: $input) {
      clientName
      customerId
      id
    }
  }
`;
export const getClientsByCustomer = /* GraphQL */ `
  query GetClientsByCustomer($input: GetClientsByCustomerInput!) {
    getClientsByCustomer(input: $input) {
      items {
        clientName
        customerId
        id
      }
      limit
      nextToken
    }
  }
`;
export const getCustomer = /* GraphQL */ `
  query GetCustomer($customerId: String!) {
    getCustomer(customerId: $customerId) {
      aggAlertingData
      allowLeakFinder
      auditCustomers
      configuration
      domains
      id
      name
      pointOfContact
      totalLeakFinderCount
      usageLeakFinderLimit
    }
  }
`;
export const getCustomerCounters = /* GraphQL */ `
  query GetCustomerCounters($customerId: String!) {
    getCustomerCounters(customerId: $customerId) {
      allowLeakFinder
      id
      totalLeakFinderCount
      usageLeakFinderLimit
    }
  }
`;
export const getCustomerDTObject = /* GraphQL */ `
  query GetCustomerDTObject($customerId: String, $objectId: String!) {
    getCustomerDTObject(customerId: $customerId, objectId: $objectId) {
      filesInfo {
        contentType
        id
        key
        originalFileName
        useType
      }
      id
      isCustomerObject
      objectId
      objectManufacturerName
      objectName
      objectType
      version
    }
  }
`;
export const getCustomerDTObjects = /* GraphQL */ `
  query GetCustomerDTObjects($customerId: String) {
    getCustomerDTObjects(customerId: $customerId) {
      filesInfo {
        contentType
        id
        key
        originalFileName
        useType
      }
      id
      isCustomerObject
      objectId
      objectManufacturerName
      objectName
      objectType
      version
    }
  }
`;
export const getCustomerHealth = /* GraphQL */ `
  query GetCustomerHealth($customerId: String) {
    getCustomerHealth(customerId: $customerId)
  }
`;
export const getCustomers = /* GraphQL */ `
  query GetCustomers($limit: Int, $nextToken: String) {
    getCustomers(limit: $limit, nextToken: $nextToken) {
      items {
        aggAlertingData
        allowLeakFinder
        auditCustomers
        configuration
        domains
        id
        name
        pointOfContact
        totalLeakFinderCount
        usageLeakFinderLimit
      }
      limit
      nextToken
    }
  }
`;
export const getDTCustomerProjects = /* GraphQL */ `
  query GetDTCustomerProjects(
    $customerId: String!
    $limit: Int
    $nextToken: String
  ) {
    getDTCustomerProjects(
      customerId: $customerId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        configURL
        customerId
        displayName
        id
        kmzLayerURL
        lastModificationDate
        lastModifierUserName
        measurementSystem
        projectId
        withKMZLayer
      }
      limit
      nextToken
    }
  }
`;
export const getDTFile = /* GraphQL */ `
  query GetDTFile($key: String) {
    getDTFile(key: $key) {
      key
      url
    }
  }
`;
export const getDTFiles = /* GraphQL */ `
  query GetDTFiles($keys: [String]) {
    getDTFiles(keys: $keys) {
      key
      url
    }
  }
`;
export const getDTObject = /* GraphQL */ `
  query GetDTObject($objectId: String!) {
    getDTObject(objectId: $objectId) {
      filesInfo {
        contentType
        id
        key
        originalFileName
        useType
      }
      id
      isCustomerObject
      objectId
      objectManufacturerName
      objectName
      objectType
      version
    }
  }
`;
export const getDTObjects = /* GraphQL */ `
  query GetDTObjects {
    getDTObjects {
      filesInfo {
        contentType
        id
        key
        originalFileName
        useType
      }
      id
      isCustomerObject
      objectId
      objectManufacturerName
      objectName
      objectType
      version
    }
  }
`;
export const getDTProject = /* GraphQL */ `
  query GetDTProject($customerId: String!, $projectId: String!) {
    getDTProject(customerId: $customerId, projectId: $projectId) {
      configURL
      customerId
      displayName
      id
      kmzLayerURL
      lastModificationDate
      lastModifierUserName
      measurementSystem
      projectId
      withKMZLayer
    }
  }
`;
export const getDTProjects = /* GraphQL */ `
  query GetDTProjects($limit: Int, $nextToken: String) {
    getDTProjects(limit: $limit, nextToken: $nextToken) {
      items {
        configURL
        customerId
        displayName
        id
        kmzLayerURL
        lastModificationDate
        lastModifierUserName
        measurementSystem
        projectId
        withKMZLayer
      }
      limit
      nextToken
    }
  }
`;
export const getDTProjectsByUsedDTObject = /* GraphQL */ `
  query GetDTProjectsByUsedDTObject($customerId: String!, $objectId: String!) {
    getDTProjectsByUsedDTObject(customerId: $customerId, objectId: $objectId) {
      configURL
      customerId
      displayName
      id
      kmzLayerURL
      lastModificationDate
      lastModifierUserName
      measurementSystem
      projectId
      withKMZLayer
    }
  }
`;
export const getDevice = /* GraphQL */ `
  query GetDevice($customerId: String!, $deviceId: String) {
    getDevice(customerId: $customerId, deviceId: $deviceId) {
      cameraIpAddress
      customerId
      deviceData
      healthData
      id
      locationId
      locationName
      makeModelId
      name
      nodeId
      nodeName
      status
    }
  }
`;
export const getDeviceById = /* GraphQL */ `
  query GetDeviceById($customerId: String!, $deviceId: String!) {
    getDeviceById(customerId: $customerId, deviceId: $deviceId) {
      cameraIpAddress
      customerId
      deviceData
      healthData
      id
      locationId
      locationName
      makeModelId
      name
      nodeId
      nodeName
      status
    }
  }
`;
export const getDeviceMakeById = /* GraphQL */ `
  query GetDeviceMakeById($makeModelId: String!) {
    getDeviceMakeById(makeModelId: $makeModelId) {
      defaultUserPwd
      deviceTypeShortName
      hasPanTilt
      id
      model
      modelsToRun
      name
      rtspTemplate
      type
    }
  }
`;
export const getDeviceMakeByModel = /* GraphQL */ `
  query GetDeviceMakeByModel($makeModelName: String!) {
    getDeviceMakeByModel(makeModelName: $makeModelName) {
      defaultUserPwd
      deviceTypeShortName
      hasPanTilt
      id
      model
      modelsToRun
      name
      rtspTemplate
      type
    }
  }
`;
export const getDevices = /* GraphQL */ `
  query GetDevices(
    $customerId: String!
    $limit: Int
    $locationId: String
    $nextToken: String
    $nodeId: String
  ) {
    getDevices(
      customerId: $customerId
      limit: $limit
      locationId: $locationId
      nextToken: $nextToken
      nodeId: $nodeId
    ) {
      items {
        cameraIpAddress
        customerId
        deviceData
        healthData
        id
        locationId
        locationName
        makeModelId
        name
        nodeId
        nodeName
        status
      }
      limit
      nextToken
    }
  }
`;
export const getDevicesByLocation = /* GraphQL */ `
  query GetDevicesByLocation($locationId: String!, $nextToken: String) {
    getDevicesByLocation(locationId: $locationId, nextToken: $nextToken) {
      items {
        cameraIpAddress
        customerId
        deviceData
        healthData
        id
        locationId
        locationName
        makeModelId
        name
        nodeId
        nodeName
        status
      }
      limit
      nextToken
    }
  }
`;
export const getDevicesByNodeId = /* GraphQL */ `
  query GetDevicesByNodeId($nextToken: String, $nodeId: String!) {
    getDevicesByNodeId(nextToken: $nextToken, nodeId: $nodeId) {
      items {
        cameraIpAddress
        customerId
        deviceData
        healthData
        id
        locationId
        locationName
        makeModelId
        name
        nodeId
        nodeName
        status
      }
      limit
      nextToken
    }
  }
`;
export const getDevicesMakes = /* GraphQL */ `
  query GetDevicesMakes($limit: Int, $nextToken: String) {
    getDevicesMakes(limit: $limit, nextToken: $nextToken) {
      items {
        defaultUserPwd
        deviceTypeShortName
        hasPanTilt
        id
        model
        modelsToRun
        name
        rtspTemplate
        type
      }
      limit
      nextToken
    }
  }
`;
export const getEventsPendingHumanValidation = /* GraphQL */ `
  query GetEventsPendingHumanValidation($limit: Int, $nextToken: String) {
    getEventsPendingHumanValidation(limit: $limit, nextToken: $nextToken) {
      items {
        audited_by
        auditorsExplanation
        customerId
        data
        eventTime
        explanation
        id
        locationId
        nodeId
        serviceId
        transitionInfo
        validated_by
        validationState
      }
      limit
      nextToken
    }
  }
`;
export const getForm = /* GraphQL */ `
  query GetForm($customerId: String!, $itemId: String!) {
    getForm(customerId: $customerId, itemId: $itemId) {
      customerId
      fields {
        entries
        itemToQuery
        name
        type
      }
      id
    }
  }
`;
export const getGroupByClientId = /* GraphQL */ `
  query GetGroupByClientId($input: GetGroupByClientIdInput!) {
    getGroupByClientId(input: $input) {
      clientId
      createdAt
      customerId
      groupName
      id
    }
  }
`;
export const getGroupsByClient = /* GraphQL */ `
  query GetGroupsByClient($input: GetGroupsByClientInput!) {
    getGroupsByClient(input: $input) {
      items {
        clientId
        createdAt
        customerId
        groupName
        id
      }
      limit
      nextToken
    }
  }
`;
export const getHumanValidatedEventsForAuditing = /* GraphQL */ `
  query GetHumanValidatedEventsForAuditing($limit: Int, $nextToken: String) {
    getHumanValidatedEventsForAuditing(limit: $limit, nextToken: $nextToken) {
      items {
        audited_by
        auditorsExplanation
        customerId
        data
        eventTime
        explanation
        id
        locationId
        nodeId
        serviceId
        transitionInfo
        validated_by
        validationState
      }
      limit
      nextToken
    }
  }
`;
export const getLicensePlate = /* GraphQL */ `
  query GetLicensePlate($categoryId: String!, $licensePlateId: String!) {
    getLicensePlate(categoryId: $categoryId, licensePlateId: $licensePlateId) {
      categoryId
      company
      driverName
      id
      licensePlate
    }
  }
`;
export const getLicensePlatesByCustomer = /* GraphQL */ `
  query GetLicensePlatesByCustomer($input: GetLicensePlatesByCustomerInput!) {
    getLicensePlatesByCustomer(input: $input) {
      items {
        categoryId
        company
        driverName
        id
        licensePlate
      }
      limit
      nextToken
    }
  }
`;
export const getLocationById = /* GraphQL */ `
  query GetLocationById($customerId: String!, $locationId: String!) {
    getLocationById(customerId: $customerId, locationId: $locationId) {
      devices {
        cameraIpAddress
        customerId
        deviceData
        healthData
        id
        locationId
        locationName
        makeModelId
        name
        nodeId
        nodeName
        status
      }
      id
      locationData
      name
      referenceId
      tags {
        associationId
        id
        tagName
      }
      timeZone
    }
  }
`;
export const getLocations = /* GraphQL */ `
  query GetLocations($customerId: String!, $limit: Int, $nextToken: String) {
    getLocations(
      customerId: $customerId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        devices {
          cameraIpAddress
          customerId
          deviceData
          healthData
          id
          locationId
          locationName
          makeModelId
          name
          nodeId
          nodeName
          status
        }
        id
        locationData
        name
        referenceId
        tags {
          associationId
          id
          tagName
        }
        timeZone
      }
      limit
      nextToken
    }
  }
`;
export const getLocationsByTag = /* GraphQL */ `
  query GetLocationsByTag(
    $customerId: String!
    $limit: Int
    $nextToken: String
    $tag: String!
  ) {
    getLocationsByTag(
      customerId: $customerId
      limit: $limit
      nextToken: $nextToken
      tag: $tag
    ) {
      items {
        devices {
          cameraIpAddress
          customerId
          deviceData
          healthData
          id
          locationId
          locationName
          makeModelId
          name
          nodeId
          nodeName
          status
        }
        id
        locationData
        name
        referenceId
        tags {
          associationId
          id
          tagName
        }
        timeZone
      }
      limit
      nextToken
    }
  }
`;
export const getNodeById = /* GraphQL */ `
  query GetNodeById($nodeId: String!) {
    getNodeById(nodeId: $nodeId) {
      awsHealth
      customerId
      deviceData
      devices {
        cameraIpAddress
        customerId
        deviceData
        healthData
        id
        locationId
        locationName
        makeModelId
        name
        nodeId
        nodeName
        status
      }
      healthData
      id
      level
      locationId
      nodeIP
      nodeId
      nodeName
      onboardCommand
      referenceId
      serviceData
      tags {
        id
        tagName
      }
    }
  }
`;
export const getNodes = /* GraphQL */ `
  query GetNodes($limit: Int, $locationId: String!, $nextToken: String) {
    getNodes(limit: $limit, locationId: $locationId, nextToken: $nextToken) {
      items {
        awsHealth
        customerId
        deviceData
        devices {
          cameraIpAddress
          customerId
          deviceData
          healthData
          id
          locationId
          locationName
          makeModelId
          name
          nodeId
          nodeName
          status
        }
        healthData
        id
        level
        locationId
        nodeIP
        nodeId
        nodeName
        onboardCommand
        referenceId
        serviceData
        tags {
          id
          tagName
        }
      }
      limit
      nextToken
    }
  }
`;
export const getNodesByCustomer = /* GraphQL */ `
  query GetNodesByCustomer(
    $customerId: String!
    $limit: Int
    $nextToken: String
  ) {
    getNodesByCustomer(
      customerId: $customerId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        awsHealth
        customerId
        deviceData
        devices {
          cameraIpAddress
          customerId
          deviceData
          healthData
          id
          locationId
          locationName
          makeModelId
          name
          nodeId
          nodeName
          status
        }
        healthData
        id
        level
        locationId
        nodeIP
        nodeId
        nodeName
        onboardCommand
        referenceId
        serviceData
        tags {
          id
          tagName
        }
      }
      limit
      nextToken
    }
  }
`;
export const getNodesByTag = /* GraphQL */ `
  query GetNodesByTag($limit: Int, $nextToken: String, $tagId: String!) {
    getNodesByTag(limit: $limit, nextToken: $nextToken, tagId: $tagId) {
      items {
        awsHealth
        customerId
        deviceData
        devices {
          cameraIpAddress
          customerId
          deviceData
          healthData
          id
          locationId
          locationName
          makeModelId
          name
          nodeId
          nodeName
          status
        }
        healthData
        id
        level
        locationId
        nodeIP
        nodeId
        nodeName
        onboardCommand
        referenceId
        serviceData
        tags {
          id
          tagName
        }
      }
      limit
      nextToken
    }
  }
`;
export const getPendingUsersByCompany = /* GraphQL */ `
  query GetPendingUsersByCompany(
    $customerId: String!
    $limit: Int
    $nextToken: String
  ) {
    getPendingUsersByCompany(
      customerId: $customerId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        customer_id
        email
        firstName
        id
        lastName
        userSettings
        user_group
        user_role
      }
      limit
      nextToken
    }
  }
`;
export const getProfile = /* GraphQL */ `
  query GetProfile {
    getProfile {
      file
      id
      name
    }
  }
`;
export const getRoles = /* GraphQL */ `
  query GetRoles($limit: Int, $nextToken: String) {
    getRoles(limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        userGroup
      }
      limit
      nextToken
    }
  }
`;
export const getSendEmail = /* GraphQL */ `
  query GetSendEmail($email: String) {
    getSendEmail(email: $email)
  }
`;
export const getServiceById = /* GraphQL */ `
  query GetServiceById($input: GetServiceInput!) {
    getServiceById(input: $input) {
      configuration
      customerId
      deviceId
      healthData
      id
      isRunning
      locationId
      nodeId
      serviceType
    }
  }
`;
export const getServices = /* GraphQL */ `
  query GetServices($customerId: String, $limit: Int, $nextToken: String) {
    getServices(customerId: $customerId, limit: $limit, nextToken: $nextToken) {
      items {
        configuration
        customerId
        deviceId
        healthData
        id
        isRunning
        locationId
        nodeId
        serviceType
      }
      limit
      nextToken
    }
  }
`;
export const getServicesByDevice = /* GraphQL */ `
  query GetServicesByDevice($deviceId: String!, $nextToken: String) {
    getServicesByDevice(deviceId: $deviceId, nextToken: $nextToken) {
      items {
        configuration
        customerId
        deviceId
        healthData
        id
        isRunning
        locationId
        nodeId
        serviceType
      }
      limit
      nextToken
    }
  }
`;
export const getServicesByLocation = /* GraphQL */ `
  query GetServicesByLocation($locationId: String!, $nextToken: String) {
    getServicesByLocation(locationId: $locationId, nextToken: $nextToken) {
      items {
        configuration
        customerId
        deviceId
        healthData
        id
        isRunning
        locationId
        nodeId
        serviceType
      }
      limit
      nextToken
    }
  }
`;
export const getShadowNode = /* GraphQL */ `
  query GetShadowNode($shadowName: String, $thingName: String) {
    getShadowNode(shadowName: $shadowName, thingName: $thingName) {
      shadow
    }
  }
`;
export const getStatisticsData = /* GraphQL */ `
  query GetStatisticsData($input: StatisticsDataInput) {
    getStatisticsData(input: $input)
  }
`;
export const getSubscriptionsForEmail = /* GraphQL */ `
  query GetSubscriptionsForEmail(
    $email: String!
    $entity: String!
    $limit: Int
    $nextToken: String
  ) {
    getSubscriptionsForEmail(
      email: $email
      entity: $entity
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        email
        entity
      }
      limit
      nextToken
    }
  }
`;
export const getTagAssociations = /* GraphQL */ `
  query GetTagAssociations($input: GetTagAssociationsInput!) {
    getTagAssociations(input: $input) {
      items {
        associationId
        id
        tagName
      }
      limit
      nextToken
    }
  }
`;
export const getTagsByCustomer = /* GraphQL */ `
  query GetTagsByCustomer($input: GetTagsByCustomerInput!) {
    getTagsByCustomer(input: $input) {
      items {
        id
        tagName
      }
      limit
      nextToken
    }
  }
`;
export const getTimestreamData = /* GraphQL */ `
  query GetTimestreamData($input: TimestreamDataInput) {
    getTimestreamData(input: $input)
  }
`;
export const getUserAlerts = /* GraphQL */ `
  query GetUserAlerts(
    $customerId: String!
    $email: String!
    $limit: Int
    $nextToken: String
  ) {
    getUserAlerts(
      customerId: $customerId
      email: $email
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        additionalEmails
        alertName
        alertType
        customService
        email
        id
        jsonObject
        location
        locationTag
        model
        node
        status
        tag
      }
      limit
      nextToken
    }
  }
`;
export const getUserById = /* GraphQL */ `
  query GetUserById($customerId: String!, $userId: String!) {
    getUserById(customerId: $customerId, userId: $userId) {
      customer_id
      email
      firstName
      id
      lastName
      userSettings
      user_group
      user_role
    }
  }
`;
export const getUsersByCompany = /* GraphQL */ `
  query GetUsersByCompany(
    $customerId: String!
    $limit: Int
    $nextToken: String
  ) {
    getUsersByCompany(
      customerId: $customerId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        customer_id
        email
        firstName
        id
        lastName
        userSettings
        user_group
        user_role
      }
      limit
      nextToken
    }
  }
`;
export const getUsersByCompanyAndFirstName = /* GraphQL */ `
  query GetUsersByCompanyAndFirstName(
    $customerId: String!
    $firstName: String!
    $limit: Int
    $nextToken: String
  ) {
    getUsersByCompanyAndFirstName(
      customerId: $customerId
      firstName: $firstName
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        customer_id
        email
        firstName
        id
        lastName
        userSettings
        user_group
        user_role
      }
      limit
      nextToken
    }
  }
`;
export const getZone = /* GraphQL */ `
  query GetZone($deviceId: String!, $zoneId: String!) {
    getZone(deviceId: $deviceId, zoneId: $zoneId) {
      alertData
      deviceId
      exclusions
      id
      inspectTime
      keyFrame
      name
      nodeId
      pan
      serviceId
      status
      tilt
    }
  }
`;
export const getZones = /* GraphQL */ `
  query GetZones(
    $customerId: String!
    $limit: Int
    $nextToken: String
    $serviceId: String!
  ) {
    getZones(
      customerId: $customerId
      limit: $limit
      nextToken: $nextToken
      serviceId: $serviceId
    ) {
      items {
        alertData
        deviceId
        exclusions
        id
        inspectTime
        keyFrame
        name
        nodeId
        pan
        serviceId
        status
        tilt
      }
      limit
      nextToken
    }
  }
`;
export const getZonesByDevice = /* GraphQL */ `
  query GetZonesByDevice(
    $customerId: String!
    $deviceId: String!
    $limit: Int
    $nextToken: String
  ) {
    getZonesByDevice(
      customerId: $customerId
      deviceId: $deviceId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        alertData
        deviceId
        exclusions
        id
        inspectTime
        keyFrame
        name
        nodeId
        pan
        serviceId
        status
        tilt
      }
      limit
      nextToken
    }
  }
`;
