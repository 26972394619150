import { useContext, useEffect, useRef } from "react";

import ViewportWrapper from "../ViewportWrapper";
import MainViewport from "./MainViewport";
import SecondaryViewport from "./SecondaryViewport";
import FPVModeInstructions from "./FPVModeInstructions";

import ThreeScene from "../../../threeWebGl/ThreeScene";

import {
  ConfiguratorPermissionContext,
  ProjectContext,
} from "../../../context";

import { findObjectAncestorsInditificator } from "../../../threeWebGl/SceneUtils/FindObjectAncestors";
import { useGetValueInCurrentMeasurementSystem } from "../../../hooks/useGetValueInCurrentMeasurementSystem";
import { MeasurementSystemMode } from "../../../enums";
import { ModeTypeEnum } from "../../../context/ProjectContext/ProjectEntitesTypes";

const ThreeCanvas = () => {
  const {
    threeScene,
    sceneStarted,
    setSceneStarted,
    setIsLoading,
    setThreeScene,
    activeObjectsListId,
    activeTowerSideIndex,
    activeCameraId,
    handleSetActiveObjectId,
    handleSetActiveTowerSideIndex,
    handleSetActiveCameraId,
    hoveredObjectId,
    hoveredTowerSideIndex,
    hoveredCameraId,
    handleSetHoveredObjectId,
    handleSetHoveredTowerSideIndex,
    handleSetHoveredCameraId,
    objects,
    cameras,
    towers,
    projectData,
    updateObjectTowerState,
    updateCameraProperties,
    secondaryCameraId,
    setSecondaryCameraId,
    secondaryViewType,
    setSecondaryViewType,
    distancesData,
    handleSetDistancesData,
    handelSetInitialDistanceData,
    transformControlMode,
    distanceMeasurementsMode,
    setDistanceMeasurementsMode,
    activeZoneId,
    measurementSystem,
    mode,
  } = useContext(ProjectContext);

  const { isAllowEditing } = useContext(ConfiguratorPermissionContext);

  const canvasMainRef = useRef(null);
  const canvasSecondaryRef = useRef(null);
  const mainViewportRef = useRef(null);
  const secondaryViewportRef = useRef(null);
  const renderer2DRef = useRef(null);

  const { getHeightValueFromMeters } =
    useGetValueInCurrentMeasurementSystem(measurementSystem);

  const resizeViewport = () => {
    const main = {
      width: mainViewportRef.current.clientWidth,
      height: mainViewportRef.current.clientHeight,
    };

    const secondary = {
      width: secondaryViewportRef.current.clientWidth,
      height: secondaryViewportRef.current.clientHeight,
    };

    threeScene.onWindowResize(main, secondary);
  };

  const handleObjectSelection = (intersection, isMultipleSelection) => {
    if (!intersection) {
      handleSetActiveObjectId(null, isMultipleSelection);
    } else {
      const { objectId, sideIndex, cameraId } =
        findObjectAncestorsInditificator(intersection.object);

      handleSetActiveObjectId(objectId, isMultipleSelection);

      if (!isMultipleSelection) {
        handleSetActiveTowerSideIndex(sideIndex);

        handleSetActiveCameraId(cameraId);
      }
    }
  };

  const handleObjectHower = intersection => {
    if (!intersection) {
      handleSetHoveredObjectId(null);

      handleSetHoveredTowerSideIndex(null);

      handleSetHoveredCameraId(null);
    } else {
      const { objectId, sideIndex, cameraId } =
        findObjectAncestorsInditificator(intersection.object);

      handleSetHoveredObjectId(objectId);

      handleSetHoveredTowerSideIndex(sideIndex);

      handleSetHoveredCameraId(cameraId);
    }
  };

  // Setup scene.
  useEffect(() => {
    const threeScene = new ThreeScene(
      canvasMainRef.current,
      canvasSecondaryRef.current,
      mainViewportRef.current,
      secondaryViewportRef.current,
      renderer2DRef.current
    );

    setSceneStarted(false);

    setThreeScene(threeScene);

    return () => {
      threeScene?.dispose();

      setSceneStarted(false);

      setThreeScene(null);
    };
  }, []);

  // Add three scene controls event listeners.
  useEffect(() => {
    if (!threeScene) {
      return;
    }

    const transformControlChangeCallBack = () => {
      updateObjectTowerState(activeObjectsListId);
    };

    isAllowEditing &&
      threeScene.sceneControls.transformControl.addEventListener(
        "objectChange",
        transformControlChangeCallBack
      );

    const lookCameraControlChangeCallBack = () => {
      updateCameraProperties([activeCameraId], activeZoneId);
    };

    isAllowEditing &&
      threeScene.sceneControls.lookCameraControl.addEventListener(
        "change",
        lookCameraControlChangeCallBack
      );

    return () => {
      threeScene.sceneControls.transformControl.removeEventListener(
        "objectChange",
        transformControlChangeCallBack
      );

      threeScene.sceneControls.lookCameraControl.removeEventListener(
        "change",
        lookCameraControlChangeCallBack
      );
    };
  }, [threeScene, activeCameraId, activeZoneId, activeObjectsListId]);

  useEffect(() => {
    if (!threeScene) {
      return;
    }

    const selectionControlOnSelectCallBack = ({ target }) => {
      if (mode === ModeTypeEnum.standard) {
        handleObjectSelection(
          target.selectIntersection,
          target.isMultipleSelection
        );
      }
    };

    const selectionControlOnHoverCallBack = ({ target }) => {
      if (mode === ModeTypeEnum.standard) {
        handleObjectHower(target.hoverIntersection);
      }
    };

    threeScene.sceneControls.objectSelectionControl.addEventListener(
      "selectObject",
      selectionControlOnSelectCallBack
    );

    threeScene.sceneControls.objectSelectionControl.addEventListener(
      "hoverObject",
      selectionControlOnHoverCallBack
    );

    return () => {
      threeScene.sceneControls.objectSelectionControl.removeEventListener(
        "selectObject",
        selectionControlOnSelectCallBack
      );

      threeScene.sceneControls.objectSelectionControl.removeEventListener(
        "hoverObject",
        selectionControlOnHoverCallBack
      );
    };
  }, [threeScene, mode]);

  // Start scene.
  useEffect(() => {
    if (!threeScene) {
      return;
    }

    let animationFrameId;

    const render = () => {
      animationFrameId = requestAnimationFrame(render);

      threeScene.update();
    };

    const startScene = async () => {
      setIsLoading(true);

      resizeViewport();

      render();

      await threeScene.loadFacility(
        projectData.configData.terrainData,
        projectData.settings.kmzLayerURL
      );

      threeScene.sceneControls.distanceMeasurementControl.addEventListener(
        "change",
        ({ target }) => handleSetDistancesData(target.result)
      );

      for (const obj of objects) {
        await threeScene.sceneFacility3dObjects.facilityObjects.addObject(obj);
      }

      for (const tower of towers) {
        await threeScene.sceneFacility3dObjects.facilityTowers.addTower(tower);
      }

      for (const camera of cameras) {
        const tower =
          threeScene.sceneFacility3dObjects.facilityTowers.getTowerById(
            camera.towerId
          );

        if (tower) {
          await threeScene.sceneFacility3dObjects.facilityCameras.addCamera(
            camera,
            tower
          );
        }
      }

      setSceneStarted(true);

      setIsLoading(false);
    };

    startScene();

    window.addEventListener("resize", resizeViewport);

    return () => {
      window.removeEventListener("resize", resizeViewport);

      cancelAnimationFrame(animationFrameId);
    };
  }, [threeScene]);

  // Add and remove object.
  useEffect(() => {
    if (!threeScene || !sceneStarted) {
      return;
    }

    const addObjectToScene = async () => {
      setIsLoading(true);

      const existingObjects =
        threeScene.sceneFacility3dObjects.facilityObjects.getAllObjects();

      for (const object of objects) {
        const existedObject = existingObjects.find(
          sceneObj => sceneObj.ID === object.id
        );

        if (!existedObject) {
          const sceneObject =
            await threeScene.sceneFacility3dObjects.facilityObjects.addObject(
              object
            );

          updateObjectTowerState([sceneObject.ID]);

          handleSetActiveObjectId(sceneObject.ID);
        }
      }

      setIsLoading(false);
    };

    const removeObjectFromScene = async () => {
      const existingObjects =
        threeScene.sceneFacility3dObjects.facilityObjects.getAllObjects();

      existingObjects.forEach(obj => {
        const objectData = objects.find(el => el.id === obj.ID);

        if (!objectData) {
          threeScene.sceneFacility3dObjects.facilityObjects.deleteObject(obj);
        }
      });
    };

    addObjectToScene();

    removeObjectFromScene();
  }, [objects]);

  // Add and remove cameras.
  useEffect(() => {
    if (!threeScene || !sceneStarted) {
      return;
    }

    const addCameraToScene = async () => {
      setIsLoading(true);

      const existingCameras =
        threeScene.sceneFacility3dObjects.facilityCameras.getAllCamera();

      for (const camera of cameras) {
        const existedCamera = existingCameras.find(
          sceneCamera => sceneCamera.ID === camera.id
        );

        if (existedCamera) {
          existedCamera.name = camera.name;
        }

        if (!existedCamera) {
          const towerObject =
            threeScene.sceneFacility3dObjects.facilityTowers.getTowerById(
              camera.towerId
            );

          const cameraSceneObject =
            await threeScene.sceneFacility3dObjects.facilityCameras.addCamera(
              camera,
              towerObject
            );

          updateCameraProperties([cameraSceneObject.ID], null);

          handleSetActiveTowerSideIndex(camera.sideIndex);

          handleSetActiveCameraId(cameraSceneObject.ID);

          setSecondaryCameraId(null);

          setSecondaryViewType(null);
        }
      }

      setIsLoading(false);
    };

    const removeCameraFromScene = async () => {
      const allCamerasSceneObjects =
        threeScene.sceneFacility3dObjects.facilityCameras.getAllCamera();

      allCamerasSceneObjects.forEach(cameraSceneObject => {
        const cameraData = cameras.find(el => el.id === cameraSceneObject.ID);

        if (!cameraData) {
          threeScene.sceneFacility3dObjects.facilityCameras.deleteCamera(
            cameraSceneObject
          );
        }
      });
    };

    addCameraToScene();

    removeCameraFromScene();
  }, [cameras]);

  // Add remove towers.
  useEffect(() => {
    if (!threeScene || !sceneStarted) {
      return;
    }

    const addTowerToScene = async () => {
      setIsLoading(true);

      const existingTowers =
        threeScene.sceneFacility3dObjects.facilityTowers.getAllTowers();

      for (const tower of towers) {
        const existedTower = existingTowers.find(
          sceneTower => sceneTower.ID === tower.id
        );

        if (existedTower) {
          existedTower.name = tower.name;
        }

        if (!existedTower) {
          const sceneTower =
            await threeScene.sceneFacility3dObjects.facilityTowers.addTower(
              tower
            );

          handleSetActiveObjectId(sceneTower.ID);

          updateObjectTowerState([sceneTower.ID]);
        }
      }

      setIsLoading(false);
    };

    const removeTowerFromScene = async () => {
      const existingTowers =
        threeScene.sceneFacility3dObjects.facilityTowers.getAllTowers();

      existingTowers.forEach(sceneTower => {
        const towerData = towers.find(el => el.id === sceneTower.ID);

        if (!towerData) {
          threeScene.sceneFacility3dObjects.facilityTowers.deleteTower(
            sceneTower
          );
        }
      });
    };

    addTowerToScene();

    removeTowerFromScene();
  }, [towers]);

  // Set scene secondary camera mode viewer.
  useEffect(() => {
    if (threeScene && sceneStarted) {
      if (secondaryCameraId && secondaryViewType) {
        const camera =
          threeScene.sceneFacility3dObjects.facilityCameras.getCameraById(
            secondaryCameraId
          );

        threeScene.secondaryCamera = camera;

        threeScene.secondaryViewType = secondaryViewType;

        resizeViewport();
      } else {
        threeScene.secondaryCamera = null;

        threeScene.secondaryViewType = null;
      }

      handelSetInitialDistanceData();

      setDistanceMeasurementsMode(false);

      threeScene.changeCortrolMode();
    }
  }, [sceneStarted, threeScene, secondaryCameraId, secondaryViewType]);

  // Set transform control mode.
  useEffect(() => {
    if (threeScene && threeScene.sceneControls.transformControl) {
      threeScene.sceneControls.transformControl.setMode(transformControlMode);
    }
  }, [threeScene, transformControlMode]);

  // Toggle transform control object.
  useEffect(() => {
    if (threeScene) {
      const allObjectsToUpdate = activeObjectsListId.map(id => {
        const object =
          threeScene.sceneFacility3dObjects.facilityObjects.getObjectById(id);
        const tower =
          threeScene.sceneFacility3dObjects.facilityTowers.getTowerById(id);

        return object || tower;
      });

      if (threeScene.sceneControls.transformControl) {
        threeScene.sceneControls.transformControl.toggleControlledObject(
          !!allObjectsToUpdate.length ? allObjectsToUpdate : null
        );
      }

      threeScene.changeCortrolMode();
    }
  }, [threeScene, activeObjectsListId]);

  // Toggle objects highlight.
  useEffect(() => {
    if (threeScene) {
      threeScene.sceneFacility3dObjects.toggleActiveObjectHighlight(
        activeObjectsListId,
        activeTowerSideIndex,
        activeCameraId,
        hoveredObjectId,
        hoveredTowerSideIndex,
        hoveredCameraId
      );
    }
  }, [
    activeObjectsListId,
    activeTowerSideIndex,
    activeCameraId,
    hoveredObjectId,
    hoveredTowerSideIndex,
    hoveredCameraId,
  ]);

  // Set distance mesurement mode.
  useEffect(() => {
    if (threeScene) {
      handelSetInitialDistanceData();

      threeScene.distanceMeasurementsMode = distanceMeasurementsMode;

      threeScene.changeCortrolMode();
    }
  }, [threeScene, distanceMeasurementsMode]);

  // Update distances value visualization.
  useEffect(() => {
    if (!threeScene) {
      return;
    }

    threeScene.distanceMeasurementLabels.removeAllLables();

    if (distanceMeasurementsMode) {
      Object.values(distancesData).forEach(data => {
        threeScene.distanceMeasurementLabels.addLable(
          data.lablePosition,
          `${getHeightValueFromMeters(data.distance).toFixed(2)} ${
            measurementSystem === MeasurementSystemMode.Metric ? "m" : "ft"
          }`
        );
      });
    }
  }, [threeScene, distancesData, distanceMeasurementsMode, measurementSystem]);

  return (
    <ViewportWrapper>
      <MainViewport viewportRef={mainViewportRef} canvasRef={canvasMainRef} />
      <FPVModeInstructions />
      <SecondaryViewport
        viewportRef={secondaryViewportRef}
        canvasRef={canvasSecondaryRef}
        renderer2DRef={renderer2DRef}
      />
    </ViewportWrapper>
  );
};

export default ThreeCanvas;
