import { useState } from "react";

interface ValidationResponseInterface {
  error: boolean;
  errorMessage: string;
}

export interface NumberStateInterface {
  value: string;
  step: number;
  min: number;
  max: number;
  error: boolean;
  errorMessage: string;
  trackError: boolean;
}

export interface UseNumberInputStateInterface {
  onChange: (text: string) => boolean;
  resetState: (state?: NumberStateInterface) => void;
  state: NumberStateInterface;
}

export const useNumberInputState = (
  defaultState: NumberStateInterface,
  validation: Function
): UseNumberInputStateInterface => {
  const [state, setState] = useState<NumberStateInterface>(defaultState);

  const doValidation = (value: string): ValidationResponseInterface => {
    if (!validation || typeof validation !== "function") {
      return { error: false, errorMessage: "" };
    }

    return validation(value, state.min, state.max, state.step);
  };

  const onChange = (value: string): boolean => {
    const { error, errorMessage } = doValidation(value);

    setState({
      ...state,
      value: value,
      error,
      errorMessage,
      trackError: true,
    });

    return error;
  };

  const resetState = (newState?: NumberStateInterface): void => {
    setState(newState || defaultState);
  };

  return { onChange, state, resetState };
};
