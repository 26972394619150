import { useContext } from "react";
import { useTheme } from "@mui/material";

import {
  GapBlock,
  HintText,
  MenuTitle,
  StyledMenuItem,
} from "../../styledComponents/styledContextMenu/ContextMenuStyledComponents";

import { useDeleteCustomerDTObject } from "../../../../pages/project-editor/hooks/useDeleteCustomerDTObject";
import { useGetDTProjectByUsedDTObject } from "../../../../pages/project-editor/hooks/useGetDTProjectByUsedDTObject";
import { useCustomerIdGuard } from "../../../../../common/hooks/useCustomerIdGuard";

import { DeleteIcon, WarningIcon } from "../../../assets/icons/svgAssets";

import {
  ConfirmationModalContext,
  NotificationContext,
  ProjectContext,
} from "../../../context";

import { DTObjectDeletionRestrictionModalContext } from "../../../../pages/project-editor/context";

import { getUniqueArrayOfObjectsByProperty } from "../../../utils";

import { DTObject } from "../../../../../API";

interface ObjectCardContextMenuDeleteButtonInterface {
  onCloseContextMenu: (event: React.MouseEvent<HTMLElement>) => void;
  objectToDelete: DTObject;
  disabledButton: boolean;
}

const ObjectCardContextMenuDeleteButton = ({
  onCloseContextMenu,
  objectToDelete,
  disabledButton,
}: ObjectCardContextMenuDeleteButtonInterface): JSX.Element => {
  const theme = useTheme();

  const selectedCustomerId = useCustomerIdGuard();
  const { deleteCustomerDTObject } = useDeleteCustomerDTObject();
  const { fetchProjectsByObjectId } = useGetDTProjectByUsedDTObject();

  const { openNotification } = useContext(NotificationContext);
  const { setConfirmationModalData } = useContext(ConfirmationModalContext);
  const { setIsLoading, getUsedObjectsAssetsIds, projectData } =
    useContext(ProjectContext);

  const { setDTObjectDeletionRestrictionData } = useContext(
    DTObjectDeletionRestrictionModalContext
  );

  const handleSetConfirmationDeleteData = () => {
    const action = async () => {
      await deleteCustomerDTObject({
        customerId: selectedCustomerId,
        objectId: objectToDelete.objectId,
      });

      openNotification({
        title: "Deleted",
        message: "Object was successfully deleted",
        severity: "success",
      });
    };

    setConfirmationModalData({
      icon: <WarningIcon fillColor={theme.palette.red.lighter} />,
      title: "Delete the object",
      message: `Are you sure you want to delete the ${objectToDelete.objectName}?`,
      actionButton: {
        color: "red",
        title: "Delete",
        action: action,
      },
    });
  };

  const handleOnClick = async (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    setIsLoading(true);

    const alreadyUsedInOtherProjectList = await fetchProjectsByObjectId(
      selectedCustomerId,
      objectToDelete.objectId
    );

    const isAlreadyUsedInCurrentProject = getUsedObjectsAssetsIds().includes(
      objectToDelete.objectId
    );

    const projectListWithContainedSelectedObject = [];

    isAlreadyUsedInCurrentProject &&
      projectListWithContainedSelectedObject.push(projectData.settings);

    !!alreadyUsedInOtherProjectList?.length &&
      projectListWithContainedSelectedObject.push(
        ...alreadyUsedInOtherProjectList
      );

    if (!!projectListWithContainedSelectedObject.length) {
      const alreadyUseInProjects = getUniqueArrayOfObjectsByProperty(
        projectListWithContainedSelectedObject,
        "id"
      );

      setDTObjectDeletionRestrictionData({
        objectData: objectToDelete,
        alreadyUseInProjects,
      });
    } else {
      handleSetConfirmationDeleteData();
    }

    setIsLoading(false);

    onCloseContextMenu(event);
  };

  return (
    <StyledMenuItem disabled={disabledButton} onClick={handleOnClick}>
      <DeleteIcon size={20} color={theme.palette["base-label"]} />
      <MenuTitle variant="body14Regular">Delete</MenuTitle>
      <GapBlock />
      <HintText>Del</HintText>
    </StyledMenuItem>
  );
};

export default ObjectCardContextMenuDeleteButton;
