import {
  GridCsvExportOptions,
  GridExportDisplayOptions,
  GridPrintExportOptions,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";

interface IExportToolbarProps {
  csvOptions?: GridCsvExportOptions & GridExportDisplayOptions;
  printOptions?: GridPrintExportOptions & GridExportDisplayOptions;
  children?: React.ReactNode;
}

const ExportToolbar = ({
  csvOptions,
  printOptions,
  children,
}: IExportToolbarProps): JSX.Element => {
  return (
    <GridToolbarContainer
      sx={{
        justifyContent: "flex-end",
      }}
    >
      {children && children}
      <GridToolbarExport csvOptions={csvOptions} printOptions={printOptions} />
    </GridToolbarContainer>
  );
};

export default ExportToolbar;
