const RGBCamera = require("../assets/3dmodels/Camera/SM_RGBCamera.fbx");
const MinervaCamera = require("../assets/3dmodels/Camera/SM_Minerva_02.fbx");
const MinervaGasCamera = require("../assets/3dmodels/Camera/SM_MinervaGas_09.fbx");

export enum CamerasType {
  RGBCamera = "RGBCamera",
  MinervaCamera = "MinervaCamera",
  MinervaGasCamera = "MinervaGasCamera",
}

export interface CameraTypeDataInterface {
  name: string;
  cameraType: CamerasType;

  modelAssets: {
    assetId: string;
    model3d: { path: string };
  };
}

const CamerasData: CameraTypeDataInterface[] = [
  {
    name: "RGB Camera",
    cameraType: CamerasType.RGBCamera,
    modelAssets: {
      assetId: CamerasType.RGBCamera,
      model3d: { path: RGBCamera },
    },
  },
  {
    name: "Minerva Camera",
    cameraType: CamerasType.MinervaCamera,
    modelAssets: {
      assetId: CamerasType.MinervaCamera,
      model3d: { path: MinervaCamera },
    },
  },
  {
    name: "Minerva Gas Camera",
    cameraType: CamerasType.MinervaGasCamera,
    modelAssets: {
      assetId: CamerasType.MinervaGasCamera,
      model3d: { path: MinervaGasCamera },
    },
  },
];

export default CamerasData;
