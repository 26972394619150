import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

import { useLazyQuery, useQuery } from "@apollo/client";

import {
  GetClientsByCustomerQuery,
  GetGroupsByClientQuery,
  PaginatedClients,
  PaginatedGroups,
} from "../../API";
import { useCustomerIdGuard } from "../../common/hooks/useCustomerIdGuard";
import {
  GET_CLIENTS_BY_CUSTOMER,
  GET_GROUPS_BY_CLIENT,
} from "../../common/operations/queries";
import { errorNotification } from "../../common/variables/notification";
import client from "../../configs/apolloClient";

export const useGetPaginatedClientsAndGroups = () => {
  const route = useLocation();
  const customerIdFromRouteState = route.state?.customerId as string;

  const selectedCustomerId = customerIdFromRouteState ?? useCustomerIdGuard();

  const [clients, setClients] = useState<PaginatedClients | null>(null);
  const [groups, setGroups] = useState<{ [clientId: string]: PaginatedGroups }>(
    {}
  );
  const [loadingGroups, setLoadingGroups] = useState<boolean>(false);

  const getClientsQuery = useQuery<GetClientsByCustomerQuery>(
    GET_CLIENTS_BY_CUSTOMER,
    {
      variables: { input: { customerId: selectedCustomerId } },
      fetchPolicy: "network-only",
      skip: !selectedCustomerId,
    }
  );

  const [getGroupsByClient, { loading: getGroupsByClientQueryLoading }] =
    useLazyQuery<GetGroupsByClientQuery>(GET_GROUPS_BY_CLIENT, {
      fetchPolicy: "network-only",
    });

  useEffect(() => {
    if (getClientsQuery.data) {
      const fetchedClients = getClientsQuery.data
        .getClientsByCustomer as PaginatedClients;

      setClients(fetchedClients);

      if (fetchedClients?.items?.length) {
        fetchGroupsForClients(fetchedClients.items.map(client => client.id));
      }
    }
  }, [getClientsQuery.data]);

  useEffect(() => {
    if (getClientsQuery.error) {
      handleRequestError();
    }
  }, [getClientsQuery.error]);

  const handleRequestError = async (): Promise<void> => {
    await client.cache.reset();

    errorNotification();
  };

  const deleteGroup = (clientId: string, groupId: string) => {
    setGroups(prevGroups => {
      return {
        ...prevGroups,
        [clientId]: {
          ...prevGroups[clientId],
          items: prevGroups[clientId].items.filter(gr => gr.id !== groupId),
        },
      };
    });
  };

  const fetchGroupsForClients = async (clientIds: string[]) => {
    if (!clientIds.length) {
      return;
    }

    setLoadingGroups(true);

    try {
      const promises = clientIds.map(clientId =>
        getGroupsByClient({
          variables: {
            input: { clientId, customerId: selectedCustomerId },
          },
        })
      );

      const groupResults = await Promise.all(promises);

      const allGroups = groupResults.reduce((acc, curr, idx) => {
        const fetchedGroups = curr.data?.getGroupsByClient;

        if (!fetchedGroups) {
          return acc;
        }

        acc[clientIds[idx]] = mergePaginatedGroups([fetchedGroups]);

        return acc;
      }, {} as { [clientId: string]: PaginatedGroups });

      setGroups(allGroups);
    } catch (error) {
      console.error("Error fetching groups for clients:", error);

      await handleRequestError();
    } finally {
      setLoadingGroups(false);
    }
  };

  const mergePaginatedGroups = (
    groupsArray: PaginatedGroups[]
  ): PaginatedGroups => {
    return groupsArray.reduce(
      (acc, curr) => {
        acc.items = [...acc.items, ...curr.items];

        acc.nextToken = curr.nextToken;

        acc.__typename = curr.__typename;

        return acc;
      },
      { items: [], nextToken: null, __typename: "PaginatedGroups" }
    );
  };

  const allGroups = useMemo(() => {
    return Object.values(groups).flat();
  }, [groups]);

  return {
    clients,
    deleteGroup,
    groups: allGroups,
    loadingClients: getClientsQuery.loading,
    loadingGroups: loadingGroups || getGroupsByClientQueryLoading,
    pageLoading:
      getClientsQuery.loading || getGroupsByClientQueryLoading || loadingGroups,
  };
};
