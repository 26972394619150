import { useContext, useEffect } from "react";

import TextInput from "../../input/TextInput";

import { useTextInputState } from "../../../hooks/useTextInputState";

import { ProjectContext } from "../../../context";
import { TowerDataInterface } from "../../../context/ProjectContext/ProjectEntitesTypes";

import { textNameValidation } from "../../../validation";

interface TowerNameInputInterface {
  activeTowers: TowerDataInterface[];
}

const TowerNameInput = ({
  activeTowers,
}: TowerNameInputInterface): JSX.Element => {
  const { updateTower } = useContext(ProjectContext);

  const towerName = useTextInputState(
    {
      value: activeTowers[0].name,
      error: false,
      errorMessage: "",
      trackError: true,
    },
    textNameValidation
  );

  const handleOnNameChange = (value: string) => {
    towerName.onChange(value);
  };

  useEffect(() => {
    if (
      activeTowers[0] &&
      !towerName.state.error &&
      towerName.state.value !== activeTowers[0].name
    ) {
      updateTower(
        activeTowers.map(t => t.id),
        { name: towerName.state.value }
      );
    }
  }, [towerName.state]);

  useEffect(() => {
    if (!!activeTowers.length) {
      const allEqual = activeTowers.every(
        item => item && item.name === activeTowers[0].name
      );

      if (allEqual) {
        if (towerName.state.value !== activeTowers[0].name) {
          handleOnNameChange(activeTowers[0].name);
        }
      } else {
        handleOnNameChange("");
      }
    }
  }, [activeTowers]);

  const invalid = !!activeTowers.length && towerName.state.error;

  return (
    <TextInput
      viewType="row"
      labelWidth="45px"
      label="Name:"
      value={towerName.state.value}
      placeholder="..."
      isError={invalid}
      disabled={!activeTowers.length}
      handleOnChange={handleOnNameChange}
    />
  );
};

export default TowerNameInput;
