import { useState } from "react";

import {
  Box,
  Button,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import ModalWindow from "../../../common/components/ModalWindow";
import OutlinedBox from "../../../common/components/OutlinedBox";
import FileInput from "../../../common/components/input/FileInput";
import TextInput from "../../../common/components/input/TextInput";
import SelectInput from "../../../common/components/input/SelectInput";

import { useUploadDataToS3 } from "../../../common/hooks/useUploadDataToS3";

import { useTextInputState } from "../../../common/hooks/useTextInputState";
import { useSelectInputState } from "../../../common/hooks/useSelectInputState";
import {
  FileStateInterface,
  useUploadFileState,
} from "../../../common/hooks/useUploadFileState";

import { useCustomerIdGuard } from "../../../../common/hooks/useCustomerIdGuard";
import { useUpdateCustomerDTObject } from "../hooks/useUpdateCustomerDTObject";

import {
  UploadDTFilesDataInterface,
  useUploadDTFiles,
} from "../hooks/useUploadDTFiles";

import {
  assets3dValidation,
  textNameValidation,
  textureImageValidation,
} from "../../../common/validation";

import {
  DTObject,
  DTObjectFileInfo,
  DTObjectFileInfoInput,
} from "../../../../API";
import {
  DTObjectsTypesEnum,
  DTObjectsFileTypeEnum,
} from "./ImportDTObjectForm";
import { getPreviouslyUploadedFile } from "../../../common/utils/dtProjectUtils/dtProjectUtils";

interface EditDTObjectFormInterface {
  objectDataToEdit: DTObject;
  onClose: () => void;
}

const EditDTObjectForm = ({
  objectDataToEdit,
  onClose,
}: EditDTObjectFormInterface): JSX.Element => {
  const theme = useTheme();

  const selectedCustomerId = useCustomerIdGuard();

  const { editCustomerDTObject } = useUpdateCustomerDTObject();
  const { addDTFiles } = useUploadDTFiles();
  const { uploadDataToS3 } = useUploadDataToS3();
  const [loading, setLoading] = useState(false);

  const model3dName = useTextInputState(
    {
      value: objectDataToEdit.objectName,
      error: false,
      trackError: true,
      errorMessage: "",
    },
    textNameValidation
  );

  const model3dType = useSelectInputState({
    value: objectDataToEdit.objectType,
    placeholder: "Select...",
    options: [...Object.values(DTObjectsTypesEnum)],
    error: false,
    trackError: true,
    errorMessage: "",
  });

  const manufacturerName = useTextInputState(
    {
      value: objectDataToEdit.objectManufacturerName,
      error: false,
      trackError: true,
      errorMessage: "",
    },
    textNameValidation
  );

  const {
    onLoadFile: onModel3dFileLoad,
    resetState: resetModel3dFileState,
    state: model3dFileState,
    loading: model3dFileLoading,
  } = useUploadFileState(
    assets3dValidation,
    DTObjectsFileTypeEnum.Model3d,
    getPreviouslyUploadedFile(
      objectDataToEdit.filesInfo,
      DTObjectsFileTypeEnum.Model3d
    )
  );

  const {
    onLoadFile: onPreviewImageFileLoad,
    resetState: resetPreviewImageFileState,
    state: previewImageFileState,
    loading: previewImageFileLoading,
  } = useUploadFileState(
    textureImageValidation,
    DTObjectsFileTypeEnum.Preview,
    getPreviouslyUploadedFile(
      objectDataToEdit.filesInfo,
      DTObjectsFileTypeEnum.Preview
    )
  );

  const {
    onLoadFile: onTextureMapFileLoad,
    resetState: resetTextureMapFileState,
    state: textureMapFileState,
    loading: textureMapFileLoading,
  } = useUploadFileState(
    textureImageValidation,
    DTObjectsFileTypeEnum.Map,
    getPreviouslyUploadedFile(
      objectDataToEdit.filesInfo,
      DTObjectsFileTypeEnum.Map
    )
  );

  const {
    onLoadFile: onTextureMetalnessMapFileLoad,
    resetState: resetTextureMetalnessMapFileState,
    state: textureMetalnessMapFileState,
    loading: textureMetalnessMapFileLoading,
  } = useUploadFileState(
    textureImageValidation,
    DTObjectsFileTypeEnum.MetalnessMap,
    getPreviouslyUploadedFile(
      objectDataToEdit.filesInfo,
      DTObjectsFileTypeEnum.MetalnessMap
    )
  );

  const {
    onLoadFile: onTextureNormalMapFileLoad,
    resetState: resetTextureNormalMapFileState,
    state: textureNormalMapFileState,
    loading: textureNormalMapFileLoading,
  } = useUploadFileState(
    textureImageValidation,
    DTObjectsFileTypeEnum.NormalMap,
    getPreviouslyUploadedFile(
      objectDataToEdit.filesInfo,
      DTObjectsFileTypeEnum.NormalMap
    )
  );

  const {
    onLoadFile: onTextureRoughnessMapFileLoad,
    resetState: resetTextureRoughnessMapFileState,
    state: textureRoughnessMapFileState,
    loading: textureRoughnessMapFileLoading,
  } = useUploadFileState(
    textureImageValidation,
    DTObjectsFileTypeEnum.RoughnessMap,
    getPreviouslyUploadedFile(
      objectDataToEdit.filesInfo,
      DTObjectsFileTypeEnum.RoughnessMap
    )
  );

  const onModel3dNameInputChange = (value: string) => {
    model3dName.onChange(value);
  };

  const onManufacturerNameInputChange = (value: string) => {
    manufacturerName.onChange(value);
  };

  const onModelTypeInputChange = (value: string) => {
    model3dType.onChange(value);
  };

  const onModel3dInputChange = (files: FileList) => {
    onModel3dFileLoad(files[0]);
  };

  const onPreviewImageInputChange = (files: FileList) => {
    onPreviewImageFileLoad(files[0]);
  };

  const onTextureMapInputChange = (files: FileList) => {
    onTextureMapFileLoad(files[0]);
  };

  const onTextureMetalnessInputChange = (files: FileList) => {
    onTextureMetalnessMapFileLoad(files[0]);
  };

  const onTextureNormalMapInputChange = (files: FileList) => {
    onTextureNormalMapFileLoad(files[0]);
  };

  const onTextureRoughnessInputChange = (files: FileList) => {
    onTextureRoughnessMapFileLoad(files[0]);
  };

  const getFileContentType = (fileState: FileStateInterface) => {
    return fileState.fileKey === "model3d"
      ? "application/octet-stream"
      : fileState.file?.type;
  };

  const addFilesPresignedUrl = async (
    objectId: string,
    filesToUpload: FileStateInterface[],
    import3dAssetsByCustomer: boolean
  ) => {
    const addDTFilesList = filesToUpload.map(state => {
      return {
        fileName: state.fileKey,
        contentType: getFileContentType(state),
      };
    });

    return await addDTFiles(
      objectId,
      import3dAssetsByCustomer ? selectedCustomerId : null,
      addDTFilesList
    );
  };

  const uploadFilesToS3 = async (
    filesPresignedUrlList: UploadDTFilesDataInterface,
    filesToUpload: FileStateInterface[]
  ) => {
    const filesToS3List = filesToUpload.map(state => {
      const dtFile = filesPresignedUrlList.uploadDTFiles.find(
        ({ key }) => state.fileKey && key?.includes(state.fileKey)
      );

      return uploadDataToS3(dtFile?.url, state.file, getFileContentType(state));
    });

    return await Promise.all(filesToS3List);
  };

  const onUpdate3dModelButton = async () => {
    setLoading(true);

    const objectId = objectDataToEdit.objectId;
    const objectName = model3dName.state.value;
    const objectManufacturerName = manufacturerName.state.value;
    const objectType = model3dType.state.value;

    const import3dAssetsByCustomer = true;

    const filesStateList = [
      model3dFileState,
      previewImageFileState,
      textureMapFileState,
      textureNormalMapFileState,
      textureMetalnessMapFileState,
      textureRoughnessMapFileState,
    ];

    const filesToUpload = filesStateList.filter(
      state => state.file && state.fileData
    );

    if (!!filesToUpload.length) {
      const filesPresignedUrlList = await addFilesPresignedUrl(
        objectId,
        filesToUpload,
        import3dAssetsByCustomer
      );

      await uploadFilesToS3(filesPresignedUrlList, filesToUpload);
    }

    const editedFilesInfo = filesStateList
      .filter(state => state.file)
      .map(state => {
        if (state.fileData) {
          return {
            originalFileName: state.file?.name,
            contentType: getFileContentType(state),
            useType: state.fileKey,
          };
        }

        const { originalFileName, contentType, useType } =
          objectDataToEdit.filesInfo.find(
            ({ useType }) => useType === state.fileKey
          ) as DTObjectFileInfo;

        return {
          originalFileName,
          contentType,
          useType,
        };
      }) as DTObjectFileInfoInput[];

    if (import3dAssetsByCustomer) {
      await editCustomerDTObject({
        customerId: selectedCustomerId,
        objectId,
        objectName,
        objectType,
        objectManufacturerName,
        filesInfo: editedFilesInfo,
      });
    }

    setLoading(false);

    onCloseModal();
  };

  const onCloseModal = () => {
    !loading && onClose();
  };

  const modelNameErrorDisabled = model3dName.state.error;

  const model3dNameError =
    model3dName.state.trackError && model3dName.state.error;

  const model3dTypeErrorDisabled = model3dType.state.error;

  const model3dTypeError =
    model3dType.state.trackError && model3dType.state.error;

  const manufacturerNameErrorDisabled = manufacturerName.state.error;

  const manufacturerNameError =
    manufacturerName.state.trackError && manufacturerName.state.error;

  const model3dFileErrorDisabled =
    !model3dFileState.file || (model3dFileState.file && model3dFileState.error);

  const previewFileErrorDisabled =
    previewImageFileState.file && previewImageFileState.error;

  const textureMapFileErrorDisabled =
    textureMapFileState.file && textureMapFileState.error;

  const textureMetalnessMapFileErrorDisabled =
    textureMetalnessMapFileState.file && textureMetalnessMapFileState.error;

  const textureNormalMapFileErrorDisabled =
    textureNormalMapFileState.file && textureNormalMapFileState.error;

  const textureRoughnessMapFileErrorDisabled =
    textureRoughnessMapFileState.file && textureRoughnessMapFileState.error;

  const disabled =
    modelNameErrorDisabled ||
    model3dTypeErrorDisabled ||
    manufacturerNameErrorDisabled ||
    model3dFileErrorDisabled ||
    previewFileErrorDisabled ||
    textureMapFileErrorDisabled ||
    textureMetalnessMapFileErrorDisabled ||
    textureNormalMapFileErrorDisabled ||
    textureRoughnessMapFileErrorDisabled;

  return (
    <ModalWindow isOpen={true} onClose={onCloseModal} modalWidth="892px">
      <FormWrapper>
        <Typography id="modal-modal-title" variant="h2">
          Edit model
        </Typography>

        <TextInputsWrapper>
          <TextInput
            label="3D model name"
            value={model3dName.state.value}
            required={true}
            isError={model3dNameError}
            hint={{
              type: !model3dNameError ? "default" : "error",
              text: !model3dNameError
                ? "From 3 to 30 characters"
                : model3dName.state.errorMessage,
            }}
            handleOnChange={onModel3dNameInputChange}
          />
          <SelectInput
            label="3D model type"
            value={model3dType.state.value}
            required={true}
            isError={model3dTypeError}
            options={model3dType.state.options}
            hint={{
              type: !model3dTypeError ? "default" : "error",
              text: model3dType.state.error ? "Select option" : "",
            }}
            handleOnChange={onModelTypeInputChange}
          />
          <TextInput
            label="Manufacturer"
            value={manufacturerName.state.value}
            required={true}
            isError={manufacturerNameError}
            hint={{
              type: !manufacturerNameError ? "default" : "error",
              text: !manufacturerNameError
                ? "From 3 to 30 characters"
                : manufacturerName.state.errorMessage,
            }}
            handleOnChange={onManufacturerNameInputChange}
          />
        </TextInputsWrapper>

        <OutlinedBox
          paddingSpacing="19px 0 20px"
          label={
            <Typography
              variant="body16Medium"
              color={theme.palette["base-label"]}
            >
              Drag and drop here or browse files
            </Typography>
          }
          borderRadius="4px"
          borderColor={theme.palette["grey-light"]}
          backgroundColor={theme.palette.base.white}
        >
          <Stack direction="row" width="100%">
            <InputsColumnWrapper>
              <FileInput
                fileState={model3dFileState}
                onClearInput={resetModel3dFileState}
                onFileUpload={onModel3dInputChange}
                fileTypeName="Input for 3d model"
                supportedFormats={{ hint: ".fbx", accept: ".fbx" }}
                required
                isLoading={model3dFileLoading}
              />
              <FileInput
                fileState={textureMapFileState}
                onClearInput={resetTextureMapFileState}
                onFileUpload={onTextureMapInputChange}
                fileTypeName="Texture map"
                supportedFormats={{
                  hint: ".jpeg .jpg .png",
                  accept: "image/jpeg, image/jpg, image/png",
                }}
                isLoading={textureMapFileLoading}
              />
              <FileInput
                fileState={textureMetalnessMapFileState}
                onClearInput={resetTextureMetalnessMapFileState}
                onFileUpload={onTextureMetalnessInputChange}
                fileTypeName="Texture metalness map"
                supportedFormats={{
                  hint: ".jpeg .jpg .png",
                  accept: "image/jpeg, image/jpg, image/png",
                }}
                isLoading={textureMetalnessMapFileLoading}
              />
            </InputsColumnWrapper>
            <InputsColumnWrapper>
              <FileInput
                fileState={previewImageFileState}
                onClearInput={resetPreviewImageFileState}
                onFileUpload={onPreviewImageInputChange}
                fileTypeName="Model 3d preview"
                supportedFormats={{
                  hint: ".jpeg .jpg .png",
                  accept: "image/jpeg, image/jpg, image/png",
                }}
                isLoading={previewImageFileLoading}
              />
              <FileInput
                fileState={textureNormalMapFileState}
                onClearInput={resetTextureNormalMapFileState}
                onFileUpload={onTextureNormalMapInputChange}
                fileTypeName="Texture normal map"
                supportedFormats={{
                  hint: ".jpeg .jpg .png",
                  accept: "image/jpeg, image/jpg, image/png",
                }}
                isLoading={textureNormalMapFileLoading}
              />
              <FileInput
                fileState={textureRoughnessMapFileState}
                onClearInput={resetTextureRoughnessMapFileState}
                onFileUpload={onTextureRoughnessInputChange}
                fileTypeName="Texture roughness map"
                supportedFormats={{
                  hint: ".jpeg .jpg .png",
                  accept: "image/jpeg, image/jpg, imge/png",
                }}
                isLoading={textureRoughnessMapFileLoading}
              />
            </InputsColumnWrapper>
          </Stack>
        </OutlinedBox>

        <Stack
          direction="row"
          spacing={1}
          paddingTop="10px"
          justifyContent="flex-end"
        >
          <Button
            disabled={loading}
            onClick={onCloseModal}
            variant="secondary"
            color="blue"
          >
            Cancel
          </Button>
          <LoadingButton
            disabled={!!disabled}
            loading={loading}
            onClick={onUpdate3dModelButton}
            variant="primary"
            color="blue"
            endIcon={<></>}
            loadingPosition="end"
          >
            Update
          </LoadingButton>
        </Stack>
      </FormWrapper>
    </ModalWindow>
  );
};

export default EditDTObjectForm;

const FormWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  "& img": {
    width: "100px",
    height: "100px",
  },
}));

const TextInputsWrapper = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  gap: "20px",
}));

const InputsColumnWrapper = styled(Box)(() => ({
  width: "50%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
}));
